/**
 * A service for retrieving and setting the bio navigation
 */
function BioNavService() {
  /**
   * The new BioNavService Object
   *
   * @param {Object}
   * @access private
   */
  var service = new Object();
  /**
   * The URL for the data file
   *
   * @type {String}
   */
  var dataFileUrl = '/data/content/{0}/pages/bio_sketches.json';

  /**
   * Build the Bio nav.
   *
   * @param  {String} langDirectory The directory for the language
   * @param  {String} elementId     The id for the element where the nav goes
   * @return {void}
   */
  service.build = function(langDirectory, elementId) {
    var file = dataFileUrl.format(langDirectory);
    $.get(file).then(function(data) {
      var headings = [];
      var html = '';
      if (data && data.hasOwnProperty('data') && data.data.hasOwnProperty('pages')) {
        $.each(data.data.pages, function(index, page) {
          headings.push({
            slug: page.slug,
            title: page.title
          });
        });
        var size = Math.ceil(headings.length/3);
        var chunks = sliceIntoChunks(headings, size);
        for (var i = 0; i < chunks.length; i++) {
          var chunk = chunks[i];
          html += '<div class="col-sm-4"><ul class="multi-column-dropdown">';
          for (var j = 0; j < chunk.length; j++) {
            var item = chunk[j];
            html += '<li><a href="/bios/' + langDirectory + '/' + item.slug + '">' + item.title + '</a></li>';
          }
          html += '</ul></div>';
        }
      }
      $('#'+elementId).html(html);
    });
  };

  /**
   * Slice an array in chunks.
   *
   * @param  {array} arr        The array to slice
   * @param  {number} chunkSize The size of a chunk
   * @return {array}            An array of chunks
   */
  function sliceIntoChunks(arr, chunkSize) {
    var chunks = [];
    for (var i = 0; i < arr.length; i+= chunkSize) {
      var chunk = arr.slice(i, i + chunkSize);
      chunks.push(chunk);
    }
    return chunks;
  }

  return service;
};
